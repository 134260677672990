// BlogNews.js
import React from 'react';
import { blogData } from '../data/mock';
import { useParams, useNavigate } from 'react-router-dom';
import BlogNewsContent from '../components/BlogNewsContent';

const BlogNews = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const currentIndex = blogData.findIndex(blog => blog.id === parseInt(id, 10));

  const handleNext = () => {
    if (currentIndex < blogData.length - 1) {
      navigate(`/blognews/${blogData[currentIndex + 1].id}`);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      navigate(`/blognews/${blogData[currentIndex - 1].id}`);
    }
  };

  const BlogDetails = {
    VatAfrica: blogData[currentIndex].VatAfrica,
    DatePosted: blogData[currentIndex].DatePosted,
    BlogHead: blogData[currentIndex].BlogHead,
    prevText: blogData[currentIndex].prevText,
    NextText: blogData[currentIndex].NextText,
    ...blogData[currentIndex], 
    onNext: handleNext,
    onPrevious: handlePrevious,
  };

  return (
    <div>
      <BlogNewsContent {...BlogDetails} />
    </div>
  );
}

export default BlogNews;
