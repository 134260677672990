import React from 'react';
import '../styles/VatepayLTDCrad.css';
import profnetImg from '../Assets/Images/proffesionalism-net-img.svg'
import vatepay3star from '../Assets/Images/vatepay-3-star.svg';
import vatepaycardmenu from '../Assets/icons/vatepay-md-MenuFold.svg';
import {testimonialData} from "../data/mock";


const VatepayLTDCrad = () => {


  return (
    <div className='VatepayCrad_main_container'>
        <div className='VatepayLTDCrad_partnershipbgImg_container'>
            <img src={profnetImg} alt="partnership net Img" />
        </div>
            <div className='VatepayCrad_container'>
                {
                testimonialData.map((data) =>(
                    <div className='mdVatepayCrad' key={data.id}>
                        <div className='mdVatepayCrad_menu_container'>
                            <img src={vatepaycardmenu} alt="vatepay card menu"  className='vatepaycardmenu_img'/>
                        </div>
                        <div className='mdVatepayCrad_img'>
                            <img src={data.testiImg} alt="Vatepay md img" />
                        </div>
                        <div className='mdVatepayCrad_column2'>
                            <h3 className='mdVatepayCradh3'>
                                {data.title}
                            </h3>
                            <h5 className='mdVatepayCradh5'>
                                {data.location}
                            </h5>
                            <div className='vatepay3_star'>
                                <img src={vatepay3star} alt=" 3 star img" />
                            </div>
                            <p className='mdVatepayCradp'>
                                {data.description}
                            </p>
                        </div>
                    </div>
                ))
                    
                }
                
            </div>
    </div>
  )
}

export default VatepayLTDCrad;