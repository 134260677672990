// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LearnMoreBtn_container{
    background-color: #5CBF14;
    border-radius: 25px;
    padding: 10px 44px;
    cursor: pointer;
}

.LearnMoreBtn_container:hover{
    background-color: black;
    transition: all 0.7s ease-in-out;
}

.LearnMoreBtn_containerp{
    font-weight: 400;
    font-size: 16px;
    line-height: 24.46px;
    text-align: center;
}


/* responsiveness stsrts here */

@media screen and (max-width: 1050px){

    .LearnMoreBtn_containerp{
        font-size: 13px;

    }
}

@media screen and (max-width: 900px){

    .LearnMoreBtn_containerp{
        font-size: 13px;

    }
}

`, "",{"version":3,"sources":["webpack://./src/styles/LearnMoreBtn.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,kBAAkB;AACtB;;;AAGA,+BAA+B;;AAE/B;;IAEI;QACI,eAAe;;IAEnB;AACJ;;AAEA;;IAEI;QACI,eAAe;;IAEnB;AACJ","sourcesContent":[".LearnMoreBtn_container{\n    background-color: #5CBF14;\n    border-radius: 25px;\n    padding: 10px 44px;\n    cursor: pointer;\n}\n\n.LearnMoreBtn_container:hover{\n    background-color: black;\n    transition: all 0.7s ease-in-out;\n}\n\n.LearnMoreBtn_containerp{\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24.46px;\n    text-align: center;\n}\n\n\n/* responsiveness stsrts here */\n\n@media screen and (max-width: 1050px){\n\n    .LearnMoreBtn_containerp{\n        font-size: 13px;\n\n    }\n}\n\n@media screen and (max-width: 900px){\n\n    .LearnMoreBtn_containerp{\n        font-size: 13px;\n\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
