// BlogNewsContent.js
import React from 'react';
import '../styles/BlogNewsContent.css';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const BlogNewsContent = ({VatAfrica, DatePosted, BlogHead, onNext, onPrevious, prevText, NextText, id, ...blogTexts}) => {
  const navigate = useNavigate();

  const paragraphs = Object.entries(blogTexts)
  .filter(([key]) => key !== 'id' && key !== 'linkText' && key !== 'linkUrl')
    .map(([key, text], index) => (
      <p key={index} className="p1">
        {text}
      </p>
    ));

    const linkText = blogTexts.linkText;
    const linkUrl = blogTexts.linkUrl;

  return (
    <div className="BlogNewsContent_container">
      <div className="BlogNewsContent_arrow_container">
        <div onClick={() => navigate('/blog')}>
          <BsArrowLeft style={{ width: '32px', height: '32px', color: '#7A7A7A' }} />
        </div>
        <div onClick={() => navigate('/blog')}>
          <span className="BlogNewsContent_back_arrow">Back to all post</span>
        </div>
      </div>
      <div className="BlogNewsContent_heading">
        <h2>{BlogHead}</h2>
        <p>
          Date posted: <span>{DatePosted}</span>
        </p>
      </div>
      <hr className="headerline" />
      <div className="BlogNewsContent_img_container">
        <img src={VatAfrica} alt="Blog Post" className="vatAfrica" />
      </div>
      <div className="BlogNewsContent_text">
        {paragraphs}
        {linkText && linkUrl && (
            <p className="p1">
                {linkText} <Link to={linkUrl}>{linkUrl}</Link>
            </p>
            )}
      </div>
      <hr className="headerline" />
      <div className="BlogNewsContent_btn_container">
        <div className="BlogNewsContent_left_btn">
          <div onClick={onPrevious} className="arrow_btn_container">
            <BsArrowLeft style={{ width: '32px', height: '32px', color: '#7A7A7A' }} />
            <button className="left_btn left_btn_bg">Previous post</button>
          </div>
          <p>{prevText}</p>
        </div>
        <div className="BlogNewsContent_right_btn">
          <div onClick={onNext} className="arrow_btn_container">
            <button className="left_btn">Next post</button>
            <BsArrowRight style={{ width: '32px', height: '32px', color: '#383838' }} />
          </div>
          <p>{NextText}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogNewsContent;
