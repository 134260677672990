import React from "react";
import AboutusCard from "../components/AboutusCard";

export const IsoPolicy = () => {
    return (
        <div>
            <div className="aboutUs_section1_container privacy_policy_container">
                <AboutusCard text1="ISO Policy" />
                <div className="policy_row">
                    <p className="aboutUs_section1_column2_text1">PURPOSE</p>
                    <p className="policy_text2">
                        This policy statement defines the framework within which the integrated management system
                        (Information Security Management System, IT Service Management, and Business Continuity Management System)
                        will be managed across Vatebra. It demonstrates management commitment and support for the integrated management
                        system throughout Vatebra.
                    </p><br />
                    <p className="policy_text2">
                        This policy serves as the primary document from which all related policies on information security,
                        business continuity, and service management emanate.
                    </p>
                </div>

                <div className="policy_row">
                    <p className="aboutUs_section1_column2_text1">SCOPE</p>
                    <p className="policy_text2">
                        This policy applies to all Vatebra personnel, contractors, vendors,
                        and other parties. It covers all information entrusted to or owned by
                        Vatebra and stored, processed, or transmitted on the organization's
                        information systems operated by the organization.
                    </p><br />
                </div>

                <div className="policy_row">
                    <p className="aboutUs_section1_column2_text1">Information Security</p>
                    <p className="policy_text2">
                        <ul className="list">
                            <li class="objective-item">Objective 1: Ensure 100% resilience of information systems.</li>
                            <li class="objective-item">Objective 2: Enhance security-awareness culture by 90%.</li>
                            <li class="objective-item"> Objective 3: Protect 100% of client confidential information.</li>
                            <li class="objective-item"> · Objective 4: Safeguard all critical information assets and business processes essential to Vatebra''s core business.</li>
                        </ul>
                    </p><br />
                </div>

                <div className="policy_row">
                    <p className="aboutUs_section1_column2_text1">Business Continuity</p>
                    <p className="policy_text2">
                        <ul className="list">
                            <li class="objective-item">Objective 1: Ensure the safety and welfare of all Vatebra staff and visitors within its premises during an incident.</li>
                            <li class="objective-item">Objective 2: Achieve 100% compliance with Vatebra's contractual, regulatory, and legal requirements.</li>
                            <li class="objective-item">Objective 3: Maintain the ability to meet all stakeholder expectations in diverse circumstances, including third-party arrangements.</li>
                            <li class="objective-item">· Objective 4: Develop a robust and enduring continuity response for 100% of identified critical areas and activities.</li>
                        </ul>
                    </p><br />
                </div>

                <div className="policy_row">
                    <p className="aboutUs_section1_column2_text1">Service Management</p>
                    <p className="policy_text2">
                        <ul className="list">
                            <li class="objective-item">Objective 1: Achieve and maintain the international standard for Service Management, BS ISO/IEC 20000-1:2018.</li>
                            <li class="objective-item">Objective 2: Attain a minimum cumulative customer satisfaction rating of 90% from annual surveys.</li>
                            <li class="objective-item">Objective 3: Ensure 100% of staff are trained in Service Management (ITIL/ISO 20000.LI).</li>
                            <li class="objective-item">Objective 4: Reduce service unavailability incidents by 10% annually.</li>
                        </ul>
                    </p><br />
                </div>

                <div className="policy_row">
                    <p className="aboutUs_section1_column2_text1">Integrated Management System Policy</p>
                    <p className="policy_text2">
                        Vatebra is committed to the confidentiality, integrity, and availability
                        of its information assets and will implement measures through the
                        establishment of a robust integrated management system. Vatebra is
                        dedicated to the continual improvement of its information security and
                        business continuity programs to protect the organization's information
                        assets against all threats.
                    </p><br />
                    <p className="policy_text2">
                        Vatebra is also committed to complying with all applicable legal,
                        regulatory, and contractual requirements related to information security
                        and business continuity in its services and operations.
                    </p>
                    <p className="policy_text2">
                    All users and custodians of information assets owned by or entrusted to
                     Vatebra must comply with this policy and exercise a duty of care in 
                     relation to the storage, processing, and transmission of the organization's 
                     information and information systems. Furthermore, Vatebra places a strong 
                     emphasis on effective IT service management practices to further enhance the
                      confidentiality, integrity, and availability of its information assets.
                    </p>
                </div>
            </div>
        </div>
    )
}
