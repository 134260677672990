// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @font-face {

    font-family: "daxplinepro";
  
    src: url('./Assets//fonts/DaxlinePro-Regular.otf') format('truetype');
  } */

*{
    padding: 0;
    margin: 0;
   box-sizing: border-box;
    /* font-family: "daxplinepro"; */
}

html, body {
  height: 100%;
  color: #ffffff;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
}



`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;;KAKK;;AAEL;IACI,UAAU;IACV,SAAS;GACV,sBAAsB;IACrB,gCAAgC;AACpC;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["/* @font-face {\n\n    font-family: \"daxplinepro\";\n  \n    src: url('./Assets//fonts/DaxlinePro-Regular.otf') format('truetype');\n  } */\n\n*{\n    padding: 0;\n    margin: 0;\n   box-sizing: border-box;\n    /* font-family: \"daxplinepro\"; */\n}\n\nhtml, body {\n  height: 100%;\n  color: #ffffff;\n}\n\nbody {\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
