// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog_container{
    padding: 100px 30px;
}

.blog_img_container{
    width: 100%;
    margin: auto;
 }

.blog_img_container img{
   width: 100%;
   height: 280px;
   object-fit: cover;
   border-radius: 15px;
}

.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    color: black;
}

.currentPage{
    border-radius: 4.171px;
    background-color: #5CBF14;
    color: black;
    padding: 10px;
}
.normalpage{
    border-radius: 4.171px;
    background-color: #8E8E8E;
    color: black;
    padding: 10px;
}

.pageNumbers{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}


@media screen and (max-width: 500px){
    .blog_container{
        padding: 100px 20px ;
        
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Blog.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;CACf;;AAED;GACG,WAAW;GACX,aAAa;GACb,iBAAiB;GACjB,mBAAmB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,YAAY;IACZ,aAAa;AACjB;AACA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;;;AAGA;IACI;QACI,oBAAoB;;IAExB;AACJ","sourcesContent":[".blog_container{\n    padding: 100px 30px;\n}\n\n.blog_img_container{\n    width: 100%;\n    margin: auto;\n }\n\n.blog_img_container img{\n   width: 100%;\n   height: 280px;\n   object-fit: cover;\n   border-radius: 15px;\n}\n\n.pagination{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 20px;\n    margin-top: 20px;\n    color: black;\n}\n\n.currentPage{\n    border-radius: 4.171px;\n    background-color: #5CBF14;\n    color: black;\n    padding: 10px;\n}\n.normalpage{\n    border-radius: 4.171px;\n    background-color: #8E8E8E;\n    color: black;\n    padding: 10px;\n}\n\n.pageNumbers{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 20px;\n}\n\n\n@media screen and (max-width: 500px){\n    .blog_container{\n        padding: 100px 20px ;\n        \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
