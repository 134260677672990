import React from 'react';
import '../styles/HomeBlog.css';
import { Link } from 'react-router-dom';
import {blogData} from '../data/mock'

const HomeBlog = () => {

  return (
    <div className='HomeBlog_main_container'>
        <div className='HomeBlog_head_container'>
            <h3 className='HomeBlog_headh3'>Blog</h3>
            <p className='HomeBlog_headp'>
                Partnership demonstrates the immeasurable power of our shared values and collective effort to effect positive, ground-breaking change and deliver excellence for our customers, colleagues, and communities.
            </p>
        </div>

        <div className='HomeBlog_gallery_container'>
        <div className='homeBlog_gallery_content'>
            {
            blogData.slice(0, 3).map((blog) =>(
            <div className='HomeBlog_gallery1' key={blog.id}>
                <div className='HomeBlog_img1_container'>
                    <img src={blog.VatAfrica} alt="Gallery img" />
                    <div className='HomeBlog_btn'>
                        <p className='HomeBlog_btnp'>
                            Technology
                        </p>
                    </div>
                </div>
                
                <div className='HomeBlog_card_container' >
                    <p className='HomeBlog_card_text1'>
                        Posted on - {blog.DatePosted}
                    </p>
                    <h6 className='HomeBlog_card_texth6'>
                        {blog.BlogHead}
                    </h6>
                    <p className='HomeBlog_card_text2'>
                        {blog.blogText1}
                    </p>
                    <span className='HomeBlog_card_text2span'>
                        <Link to={`/blognews/${blog.id}`}>Continue reading</Link>
                    </span>
                </div>
            </div>
            ))
            }
                
            </div>
            <div className='view_more_btn'>
                <Link to='/blog'>View all</Link>
            </div>

        </div>
        


    </div>
  )
}

export default HomeBlog;